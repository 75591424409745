<template>
  <base-layout>
    <IotCapabilitiesAddEdit />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus title="IoT Capabilities" :hide-plus="true" />
        </div>
        <div class="summary-card-container">
          <SummaryCard
            title="Total IoT Types"
            :value="indexMetaData.count.total"
            variant="gray"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="fstId"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="endpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  className="text-blue-600 cursor-pointer"
                  @click="edit(item)"
                  :text="item.iot_category.replaceAll('_', ' ')"
                />
                <FSTableRowItem :text="item.port ? item.port : 'N/A'" />
                <FSTableRowItem :text="item.created_at" date />
                <FSTableRowItem :text="item.updated_at" date />
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" /></div
                ></FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  className="text-blue-600 cursor-pointer"
                  @click="edit(item)"
                  :text="item.iot_category.replaceAll('_', ' ')"
                />
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" /></div
                ></FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Port</div>
                    <div class="col-span-5 right-text">
                      {{ item.port ? item.port : 'N/A' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Created At</div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.created_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Updated At</div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.updated_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { IotCapabilitiesConfig } from '@/config/IotCapabilitiesConfig'
import { EventBus } from '@/utils'
import SummaryCard from '@/components/cards/SummaryCard'
import IotCapabilitiesAddEdit from './IotCapabilitiesAddEdit'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'IotCapabilitiesIndex',
  props: {
    fstId: {
      type: String,
      default: 'iot-capabilities-index',
      required: false,
    },
    endpoint: {
      type: String,
      default: IotCapabilitiesConfig.api.index,
      required: false,
    },
  },
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    SummaryCard,
    IotCapabilitiesAddEdit,
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `IoT Type`,
          width: '40%',
          sort: 'iot_category',
        },

        {
          text: `Port`,
          width: '10%',
          sort: 'port',
        },

        {
          text: `Created At`,
          width: '20%',
          sort: 'created_at',
        },

        {
          text: `Updated At`,
          width: '20%',
          sort: 'updated_at',
        },
        {
          text: `Actions`,
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `IoT Type`,
          width: '80%',
          sort: 'iot_category',
        },
        {
          text: `Actions`,
          width: '10%',
          sort: null,
        },
      ],
    }
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(IotCapabilitiesConfig.events.editingData, item)
      dispatchEvent(new Event(IotCapabilitiesConfig.events.sorToggle))
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
